import * as React from "react";
import { BaseScreen, LinkButton, Title } from "client/components";
import { COLOR_MAP } from "@bsg/shared/constants";

const Landing: React.FC = () => (
  <BaseScreen
    showHex
    showStars
    showEntities
    sidebar={
      <>
        <div
          style={{
            margin: 20,
          }}
        >
          <LinkButton title={"CREATE GAME"} link={"/host"} />
        </div>
        <div
          style={{
            margin: 20,
            marginTop: 0,
          }}
        >
          <LinkButton title={"JOIN GAME"} link={"/join"} />
        </div>
        <div
          style={{
            margin: 20,
            marginTop: 0,
          }}
        >
          <LinkButton title={"ABOUT"} link={"/about"} />
        </div>
      </>
    }
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Title title={"BORED"} noline />
      <SpaceBarIcon />
      <Title title={"GAMES"} noline />
    </div>
  </BaseScreen>
);

const SpaceBarIcon: React.FC = () => {
  const [spaceDown, setSpaceDown] = React.useState(false);
  const [backgroundColor, setBackgroundColor] = React.useState(undefined);
  React.useEffect(() => {
    let timeout = null;
    const onDown = (e: KeyboardEvent) => {
      if (e.key === " ") {
        if (timeout) clearTimeout(timeout);
        const colors = Object.values(COLOR_MAP);
        const color = colors[Math.floor(Math.random() * colors.length)];
        setBackgroundColor(color);
        setSpaceDown(true);
      }
    };
    const onUp = (e: KeyboardEvent) => {
      if (e.key === " ") {
        timeout = setTimeout(() => {
          setSpaceDown(false);
        }, 50);
      }
    };
    addEventListener("keydown", onDown);
    addEventListener("keyup", onUp);
    return () => {
      removeEventListener("keydown", onDown);
      removeEventListener("keyup", onUp);
    };
  }, []);

  return (
    <div
      style={{
        width: "80%",
        height: 40,
        margin: 10,
        marginBottom: 14,
        boxSizing: "border-box",
        borderRadius: "0 0 8px 8px",
        borderTop: spaceDown ? "8px solid black" : "8px solid transparent",
        backgroundColor: spaceDown ? backgroundColor : undefined,
        borderLeft: "8px solid black",
        borderRight: "8px solid black",
        borderBottom: "8px solid black",
        transition: "all 0.2s",
      }}
    />
  );
};

export default Landing;
